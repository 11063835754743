import { createI18n } from "vue-i18n";

import de from "@/locales/de.json";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import fr from "@/locales/fr.json";
import it from "@/locales/it.json";
import pt from "@/locales/pt.json";
import ro from "@/locales/ro.json";

import ar from "@/locales/ar.json";
import bn from "@/locales/bn.json";
import hi from "@/locales/hi.json";
import id from "@/locales/id.json";
import ja from "@/locales/ja.json";
import ko from "@/locales/ko.json";
import pl from "@/locales/pl.json";
import ta from "@/locales/ta.json";
import tl from "@/locales/tl.json";
import tr from "@/locales/tr.json";
import uk from "@/locales/uk.json";
import vi from "@/locales/vi.json";
import zh from "@/locales/zh.json";

// Luiz
let locale = "en";
locale = navigator.language.split("-")[0];
// locale = /(de|en|es|fr|it|pt|ro)/gi.test(locale) ? locale : 'en'
localStorage.locale = locale;

const langs_pt = [
  { text: pt.LANG_PT, value: "pt" },
  { text: pt.LANG_EN, value: "en" },
  { text: pt.LANG_DE, value: "de" },
  { text: pt.LANG_ES, value: "es" },
  { text: pt.LANG_IT, value: "it" },
  { text: pt.LANG_FR, value: "fr" },
  { text: pt.LANG_RO, value: "ro" },

  { text: pt.LANG_AR, value: "ar" },
  { text: pt.LANG_BN, value: "bn" },
  { text: pt.LANG_HI, value: "hi" },
  { text: pt.LANG_ID, value: "id" },
  { text: pt.LANG_JA, value: "ja" },
  { text: pt.LANG_KO, value: "ko" },
  { text: pt.LANG_PL, value: "pl" },
  { text: pt.LANG_TA, value: "ta" },
  { text: pt.LANG_TL, value: "tl" },
  { text: pt.LANG_TR, value: "tr" },
  { text: pt.LANG_UK, value: "uk" },
  { text: pt.LANG_VI, value: "vi" },
  { text: pt.LANG_ZH, value: "zh-cn" },
];

const langs_en = [
  { text: en.LANG_PT, value: "pt" },
  { text: en.LANG_EN, value: "en" },
  { text: en.LANG_DE, value: "de" },
  { text: en.LANG_ES, value: "es" },
  { text: en.LANG_IT, value: "it" },
  { text: en.LANG_FR, value: "fr" },
  { text: en.LANG_RO, value: "ro" },

  { text: en.LANG_AR, value: "ar" },
  { text: en.LANG_BN, value: "bn" },
  { text: en.LANG_HI, value: "hi" },
  { text: en.LANG_ID, value: "id" },
  { text: en.LANG_JA, value: "ja" },
  { text: en.LANG_KO, value: "ko" },
  { text: en.LANG_PL, value: "pl" },
  { text: en.LANG_TA, value: "ta" },
  { text: en.LANG_TL, value: "tl" },
  { text: en.LANG_TR, value: "tr" },
  { text: en.LANG_UK, value: "uk" },
  { text: en.LANG_VI, value: "vi" },
  { text: en.LANG_ZH, value: "zh-cn" },
];

const langs_de = [
  { text: de.LANG_PT, value: "pt" },
  { text: de.LANG_EN, value: "en" },
  { text: de.LANG_DE, value: "de" },
  { text: de.LANG_ES, value: "es" },
  { text: de.LANG_IT, value: "it" },
  { text: de.LANG_FR, value: "fr" },
  { text: de.LANG_RO, value: "ro" },

  { text: de.LANG_AR, value: "ar" },
  { text: de.LANG_BN, value: "bn" },
  { text: de.LANG_HI, value: "hi" },
  { text: de.LANG_ID, value: "id" },
  { text: de.LANG_JA, value: "ja" },
  { text: de.LANG_KO, value: "ko" },
  { text: de.LANG_PL, value: "pl" },
  { text: de.LANG_TA, value: "ta" },
  { text: de.LANG_TL, value: "tl" },
  { text: de.LANG_TR, value: "tr" },
  { text: de.LANG_UK, value: "uk" },
  { text: de.LANG_VI, value: "vi" },
  { text: de.LANG_ZH, value: "zh-cn" },
];

const langs_es = [
  { text: es.LANG_PT, value: "pt" },
  { text: es.LANG_EN, value: "en" },
  { text: es.LANG_DE, value: "de" },
  { text: es.LANG_ES, value: "es" },
  { text: es.LANG_IT, value: "it" },
  { text: es.LANG_FR, value: "fr" },
  { text: es.LANG_RO, value: "ro" },

  { text: es.LANG_AR, value: "ar" },
  { text: es.LANG_BN, value: "bn" },
  { text: es.LANG_HI, value: "hi" },
  { text: es.LANG_ID, value: "id" },
  { text: es.LANG_JA, value: "ja" },
  { text: es.LANG_KO, value: "ko" },
  { text: es.LANG_PL, value: "pl" },
  { text: es.LANG_TA, value: "ta" },
  { text: es.LANG_TL, value: "tl" },
  { text: es.LANG_TR, value: "tr" },
  { text: es.LANG_UK, value: "uk" },
  { text: es.LANG_VI, value: "vi" },
  { text: es.LANG_ZH, value: "zh-cn" },
];

const langs_it = [
  { text: it.LANG_PT, value: "pt" },
  { text: it.LANG_EN, value: "en" },
  { text: it.LANG_DE, value: "de" },
  { text: it.LANG_ES, value: "es" },
  { text: it.LANG_IT, value: "it" },
  { text: it.LANG_FR, value: "fr" },
  { text: it.LANG_RO, value: "ro" },

  { text: it.LANG_AR, value: "ar" },
  { text: it.LANG_BN, value: "bn" },
  { text: it.LANG_HI, value: "hi" },
  { text: it.LANG_ID, value: "id" },
  { text: it.LANG_JA, value: "ja" },
  { text: it.LANG_KO, value: "ko" },
  { text: it.LANG_PL, value: "pl" },
  { text: it.LANG_TA, value: "ta" },
  { text: it.LANG_TL, value: "tl" },
  { text: it.LANG_TR, value: "tr" },
  { text: it.LANG_UK, value: "uk" },
  { text: it.LANG_VI, value: "vi" },
  { text: it.LANG_ZH, value: "zh-cn" },
];

const langs_fr = [
  { text: fr.LANG_PT, value: "pt" },
  { text: fr.LANG_EN, value: "en" },
  { text: fr.LANG_DE, value: "de" },
  { text: fr.LANG_ES, value: "es" },
  { text: fr.LANG_IT, value: "it" },
  { text: fr.LANG_FR, value: "fr" },
  { text: fr.LANG_RO, value: "ro" },

  { text: fr.LANG_AR, value: "ar" },
  { text: fr.LANG_BN, value: "bn" },
  { text: fr.LANG_HI, value: "hi" },
  { text: fr.LANG_ID, value: "id" },
  { text: fr.LANG_JA, value: "ja" },
  { text: fr.LANG_KO, value: "ko" },
  { text: fr.LANG_PL, value: "pl" },
  { text: fr.LANG_TA, value: "ta" },
  { text: fr.LANG_TL, value: "tl" },
  { text: fr.LANG_TR, value: "tr" },
  { text: fr.LANG_UK, value: "uk" },
  { text: fr.LANG_VI, value: "vi" },
  { text: fr.LANG_ZH, value: "zh-cn" },
];

const langs_ro = [
  { text: ro.LANG_PT, value: "pt" },
  { text: ro.LANG_EN, value: "en" },
  { text: ro.LANG_DE, value: "de" },
  { text: ro.LANG_ES, value: "es" },
  { text: ro.LANG_IT, value: "it" },
  { text: ro.LANG_FR, value: "fr" },
  { text: ro.LANG_RO, value: "ro" },

  { text: ro.LANG_AR, value: "ar" },
  { text: ro.LANG_BN, value: "bn" },
  { text: ro.LANG_HI, value: "hi" },
  { text: ro.LANG_ID, value: "id" },
  { text: ro.LANG_JA, value: "ja" },
  { text: ro.LANG_KO, value: "ko" },
  { text: ro.LANG_PL, value: "pl" },
  { text: ro.LANG_TA, value: "ta" },
  { text: ro.LANG_TL, value: "tl" },
  { text: ro.LANG_TR, value: "tr" },
  { text: ro.LANG_UK, value: "uk" },
  { text: ro.LANG_VI, value: "vi" },
  { text: ro.LANG_ZH, value: "zh-cn" },
];

const langs_ar = [
  { text: ar.LANG_PT, value: "pt" },
  { text: ar.LANG_EN, value: "en" },
  { text: ar.LANG_DE, value: "de" },
  { text: ar.LANG_ES, value: "es" },
  { text: ar.LANG_IT, value: "it" },
  { text: ar.LANG_FR, value: "fr" },
  { text: ar.LANG_RO, value: "ro" },

  { text: ar.LANG_AR, value: "ar" },
  { text: ar.LANG_BN, value: "bn" },
  { text: ar.LANG_HI, value: "hi" },
  { text: ar.LANG_ID, value: "id" },
  { text: ar.LANG_JA, value: "ja" },
  { text: ar.LANG_KO, value: "ko" },
  { text: ar.LANG_PL, value: "pl" },
  { text: ar.LANG_TA, value: "ta" },
  { text: ar.LANG_TL, value: "tl" },
  { text: ar.LANG_TR, value: "tr" },
  { text: ar.LANG_UK, value: "uk" },
  { text: ar.LANG_VI, value: "vi" },
  { text: ar.LANG_ZH, value: "zh-cn" },
];

const langs_bn = [
  { text: bn.LANG_PT, value: "pt" },
  { text: bn.LANG_EN, value: "en" },
  { text: bn.LANG_DE, value: "de" },
  { text: bn.LANG_ES, value: "es" },
  { text: bn.LANG_IT, value: "it" },
  { text: bn.LANG_FR, value: "fr" },
  { text: bn.LANG_RO, value: "ro" },

  { text: bn.LANG_AR, value: "ar" },
  { text: bn.LANG_BN, value: "bn" },
  { text: bn.LANG_HI, value: "hi" },
  { text: bn.LANG_ID, value: "id" },
  { text: bn.LANG_JA, value: "ja" },
  { text: bn.LANG_KO, value: "ko" },
  { text: bn.LANG_PL, value: "pl" },
  { text: bn.LANG_TA, value: "ta" },
  { text: bn.LANG_TL, value: "tl" },
  { text: bn.LANG_TR, value: "tr" },
  { text: bn.LANG_UK, value: "uk" },
  { text: bn.LANG_VI, value: "vi" },
  { text: bn.LANG_ZH, value: "zh-cn" },
];

const langs_hi = [
  { text: hi.LANG_PT, value: "pt" },
  { text: hi.LANG_EN, value: "en" },
  { text: hi.LANG_DE, value: "de" },
  { text: hi.LANG_ES, value: "es" },
  { text: hi.LANG_IT, value: "it" },
  { text: hi.LANG_FR, value: "fr" },
  { text: hi.LANG_RO, value: "ro" },

  { text: hi.LANG_AR, value: "ar" },
  { text: hi.LANG_BN, value: "bn" },
  { text: hi.LANG_HI, value: "hi" },
  { text: hi.LANG_ID, value: "id" },
  { text: hi.LANG_JA, value: "ja" },
  { text: hi.LANG_KO, value: "ko" },
  { text: hi.LANG_PL, value: "pl" },
  { text: hi.LANG_TA, value: "ta" },
  { text: hi.LANG_TL, value: "tl" },
  { text: hi.LANG_TR, value: "tr" },
  { text: hi.LANG_UK, value: "uk" },
  { text: hi.LANG_VI, value: "vi" },
  { text: hi.LANG_ZH, value: "zh-cn" },
];

const langs_id = [
  { text: id.LANG_PT, value: "pt" },
  { text: id.LANG_EN, value: "en" },
  { text: id.LANG_DE, value: "de" },
  { text: id.LANG_ES, value: "es" },
  { text: id.LANG_IT, value: "it" },
  { text: id.LANG_FR, value: "fr" },
  { text: id.LANG_RO, value: "ro" },

  { text: id.LANG_AR, value: "ar" },
  { text: id.LANG_BN, value: "bn" },
  { text: id.LANG_HI, value: "hi" },
  { text: id.LANG_ID, value: "id" },
  { text: id.LANG_JA, value: "ja" },
  { text: id.LANG_KO, value: "ko" },
  { text: id.LANG_PL, value: "pl" },
  { text: id.LANG_TA, value: "ta" },
  { text: id.LANG_TL, value: "tl" },
  { text: id.LANG_TR, value: "tr" },
  { text: id.LANG_UK, value: "uk" },
  { text: id.LANG_VI, value: "vi" },
  { text: id.LANG_ZH, value: "zh-cn" },
];

const langs_ja = [
  { text: ja.LANG_PT, value: "pt" },
  { text: ja.LANG_EN, value: "en" },
  { text: ja.LANG_DE, value: "de" },
  { text: ja.LANG_ES, value: "es" },
  { text: ja.LANG_IT, value: "it" },
  { text: ja.LANG_FR, value: "fr" },
  { text: ja.LANG_RO, value: "ro" },

  { text: ja.LANG_AR, value: "ar" },
  { text: ja.LANG_BN, value: "bn" },
  { text: ja.LANG_HI, value: "hi" },
  { text: ja.LANG_ID, value: "id" },
  { text: ja.LANG_JA, value: "ja" },
  { text: ja.LANG_KO, value: "ko" },
  { text: ja.LANG_PL, value: "pl" },
  { text: ja.LANG_TA, value: "ta" },
  { text: ja.LANG_TL, value: "tl" },
  { text: ja.LANG_TR, value: "tr" },
  { text: ja.LANG_UK, value: "uk" },
  { text: ja.LANG_VI, value: "vi" },
  { text: ja.LANG_ZH, value: "zh-cn" },
];

const langs_ko = [
  { text: ko.LANG_PT, value: "pt" },
  { text: ko.LANG_EN, value: "en" },
  { text: ko.LANG_DE, value: "de" },
  { text: ko.LANG_ES, value: "es" },
  { text: ko.LANG_IT, value: "it" },
  { text: ko.LANG_FR, value: "fr" },
  { text: ko.LANG_RO, value: "ro" },

  { text: ko.LANG_AR, value: "ar" },
  { text: ko.LANG_BN, value: "bn" },
  { text: ko.LANG_HI, value: "hi" },
  { text: ko.LANG_ID, value: "id" },
  { text: ko.LANG_JA, value: "ja" },
  { text: ko.LANG_KO, value: "ko" },
  { text: ko.LANG_PL, value: "pl" },
  { text: ko.LANG_TA, value: "ta" },
  { text: ko.LANG_TL, value: "tl" },
  { text: ko.LANG_TR, value: "tr" },
  { text: ko.LANG_UK, value: "uk" },
  { text: ko.LANG_VI, value: "vi" },
  { text: ko.LANG_ZH, value: "zh-cn" },
];

const langs_pl = [
  { text: pl.LANG_PT, value: "pt" },
  { text: pl.LANG_EN, value: "en" },
  { text: pl.LANG_DE, value: "de" },
  { text: pl.LANG_ES, value: "es" },
  { text: pl.LANG_IT, value: "it" },
  { text: pl.LANG_FR, value: "fr" },
  { text: pl.LANG_RO, value: "ro" },

  { text: pl.LANG_AR, value: "ar" },
  { text: pl.LANG_BN, value: "bn" },
  { text: pl.LANG_HI, value: "hi" },
  { text: pl.LANG_ID, value: "id" },
  { text: pl.LANG_JA, value: "ja" },
  { text: pl.LANG_KO, value: "ko" },
  { text: pl.LANG_PL, value: "pl" },
  { text: pl.LANG_TA, value: "ta" },
  { text: pl.LANG_TL, value: "tl" },
  { text: pl.LANG_TR, value: "tr" },
  { text: pl.LANG_UK, value: "uk" },
  { text: pl.LANG_VI, value: "vi" },
  { text: pl.LANG_ZH, value: "zh-cn" },
];

const langs_ta = [
  { text: ta.LANG_PT, value: "pt" },
  { text: ta.LANG_EN, value: "en" },
  { text: ta.LANG_DE, value: "de" },
  { text: ta.LANG_ES, value: "es" },
  { text: ta.LANG_IT, value: "it" },
  { text: ta.LANG_FR, value: "fr" },
  { text: ta.LANG_RO, value: "ro" },

  { text: ta.LANG_AR, value: "ar" },
  { text: ta.LANG_BN, value: "bn" },
  { text: ta.LANG_HI, value: "hi" },
  { text: ta.LANG_ID, value: "id" },
  { text: ta.LANG_JA, value: "ja" },
  { text: ta.LANG_KO, value: "ko" },
  { text: ta.LANG_PL, value: "pl" },
  { text: ta.LANG_TA, value: "ta" },
  { text: ta.LANG_TL, value: "tl" },
  { text: ta.LANG_TR, value: "tr" },
  { text: ta.LANG_UK, value: "uk" },
  { text: ta.LANG_VI, value: "vi" },
  { text: ta.LANG_ZH, value: "zh-cn" },
];

const langs_tl = [
  { text: tl.LANG_PT, value: "pt" },
  { text: tl.LANG_EN, value: "en" },
  { text: tl.LANG_DE, value: "de" },
  { text: tl.LANG_ES, value: "es" },
  { text: tl.LANG_IT, value: "it" },
  { text: tl.LANG_FR, value: "fr" },
  { text: tl.LANG_RO, value: "ro" },

  { text: tl.LANG_AR, value: "ar" },
  { text: tl.LANG_BN, value: "bn" },
  { text: tl.LANG_HI, value: "hi" },
  { text: tl.LANG_ID, value: "id" },
  { text: tl.LANG_JA, value: "ja" },
  { text: tl.LANG_KO, value: "ko" },
  { text: tl.LANG_PL, value: "pl" },
  { text: tl.LANG_TA, value: "ta" },
  { text: tl.LANG_TL, value: "tl" },
  { text: tl.LANG_TR, value: "tr" },
  { text: tl.LANG_UK, value: "uk" },
  { text: tl.LANG_VI, value: "vi" },
  { text: tl.LANG_ZH, value: "zh-cn" },
];

const langs_tr = [
  { text: tr.LANG_PT, value: "pt" },
  { text: tr.LANG_EN, value: "en" },
  { text: tr.LANG_DE, value: "de" },
  { text: tr.LANG_ES, value: "es" },
  { text: tr.LANG_IT, value: "it" },
  { text: tr.LANG_FR, value: "fr" },
  { text: tr.LANG_RO, value: "ro" },

  { text: tr.LANG_AR, value: "ar" },
  { text: tr.LANG_BN, value: "bn" },
  { text: tr.LANG_HI, value: "hi" },
  { text: tr.LANG_ID, value: "id" },
  { text: tr.LANG_JA, value: "ja" },
  { text: tr.LANG_KO, value: "ko" },
  { text: tr.LANG_PL, value: "pl" },
  { text: tr.LANG_TA, value: "ta" },
  { text: tr.LANG_TL, value: "tl" },
  { text: tr.LANG_TR, value: "tr" },
  { text: tr.LANG_UK, value: "uk" },
  { text: tr.LANG_VI, value: "vi" },
  { text: tr.LANG_ZH, value: "zh-cn" },
];

const langs_uk = [
  { text: uk.LANG_PT, value: "pt" },
  { text: uk.LANG_EN, value: "en" },
  { text: uk.LANG_DE, value: "de" },
  { text: uk.LANG_ES, value: "es" },
  { text: uk.LANG_IT, value: "it" },
  { text: uk.LANG_FR, value: "fr" },
  { text: uk.LANG_RO, value: "ro" },

  { text: uk.LANG_AR, value: "ar" },
  { text: uk.LANG_BN, value: "bn" },
  { text: uk.LANG_HI, value: "hi" },
  { text: uk.LANG_ID, value: "id" },
  { text: uk.LANG_JA, value: "ja" },
  { text: uk.LANG_KO, value: "ko" },
  { text: uk.LANG_PL, value: "pl" },
  { text: uk.LANG_TA, value: "ta" },
  { text: uk.LANG_TL, value: "tl" },
  { text: uk.LANG_TR, value: "tr" },
  { text: uk.LANG_UK, value: "uk" },
  { text: uk.LANG_VI, value: "vi" },
  { text: uk.LANG_ZH, value: "zh-cn" },
];

const langs_vi = [
  { text: vi.LANG_PT, value: "pt" },
  { text: vi.LANG_EN, value: "en" },
  { text: vi.LANG_DE, value: "de" },
  { text: vi.LANG_ES, value: "es" },
  { text: vi.LANG_IT, value: "it" },
  { text: vi.LANG_FR, value: "fr" },
  { text: vi.LANG_RO, value: "ro" },

  { text: vi.LANG_AR, value: "ar" },
  { text: vi.LANG_BN, value: "bn" },
  { text: vi.LANG_HI, value: "hi" },
  { text: vi.LANG_ID, value: "id" },
  { text: vi.LANG_JA, value: "ja" },
  { text: vi.LANG_KO, value: "ko" },
  { text: vi.LANG_PL, value: "pl" },
  { text: vi.LANG_TA, value: "ta" },
  { text: vi.LANG_TL, value: "tl" },
  { text: vi.LANG_TR, value: "tr" },
  { text: vi.LANG_UK, value: "uk" },
  { text: vi.LANG_VI, value: "vi" },
  { text: vi.LANG_ZH, value: "zh-cn" },
];

const langs_zh = [
  { text: zh.LANG_PT, value: "pt" },
  { text: zh.LANG_EN, value: "en" },
  { text: zh.LANG_DE, value: "de" },
  { text: zh.LANG_ES, value: "es" },
  { text: zh.LANG_IT, value: "it" },
  { text: zh.LANG_FR, value: "fr" },
  { text: zh.LANG_RO, value: "ro" },

  { text: zh.LANG_AR, value: "ar" },
  { text: zh.LANG_BN, value: "bn" },
  { text: zh.LANG_HI, value: "hi" },
  { text: zh.LANG_ID, value: "id" },
  { text: zh.LANG_JA, value: "ja" },
  { text: zh.LANG_KO, value: "ko" },
  { text: zh.LANG_PL, value: "pl" },
  { text: zh.LANG_TA, value: "ta" },
  { text: zh.LANG_TL, value: "tl" },
  { text: zh.LANG_TR, value: "tr" },
  { text: zh.LANG_UK, value: "uk" },
  { text: zh.LANG_VI, value: "vi" },
  { text: zh.LANG_ZH, value: "zh-cn" },
];

let languages = langs_pt;

switch (locale) {
  case "pt":
    languages = langs_pt;
    break;
  case "en":
    languages = langs_en;
    break;
  case "de":
    languages = langs_de;
    break;
  case "es":
    languages = langs_es;
    break;
  case "it":
    languages = langs_it;
    break;
  case "fr":
    languages = langs_fr;
    break;
  case "ro":
    languages = langs_ro;
    break;
  case "ar":
    languages = langs_ar;
    break;
  case "bn":
    languages = langs_bn;
    break;
  case "hi":
    languages = langs_hi;
    break;
  case "id":
    languages = langs_id;
    break;
  case "ja":
    languages = langs_ja;
    break;
  case "ko":
    languages = langs_ko;
    break;
  case "pl":
    languages = langs_pl;
    break;
  case "ta":
    languages = langs_ta;
    break;
  case "tl":
    languages = langs_tl;
    break;
  case "tr":
    languages = langs_tr;
    break;
  case "uk":
    languages = langs_uk;
    break;
  case "vi":
    languages = langs_vi;
    break;
  case "zh-cn":
    languages = langs_zh;
    break;
}

export { languages };

export default createI18n({
  locale: locale || process.env.VUE_APP_I18N_LOCALE || "pt",
  fallbackLocale: locale || process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pt",
  globalInjection: true,
  messages: {
    de,
    en,
    es,
    fr,
    it,
    pt,
    ro,
    ar,
    bn,
    hi,
    id,
    ja,
    ko,
    pl,
    ta,
    tl,
    tr,
    uk,
    vi,
    zh,
  },
});

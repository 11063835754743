<template>
  <nav>
    <router-link to="/">{{ $t("MSG000", language) }}</router-link> |
    <router-link to="/install">{{ $t("MSG100", language) }}</router-link> |
    <router-link to="/notification">{{ $t("MSG200", language) }}</router-link> |
    <router-link to="/troubleshooting">{{
      $t("MSG300", language)
    }}</router-link>
    |
    <router-link to="/about">{{ $t("MSG400", language) }}</router-link>
  </nav>
  <router-view />
</template>

<script>
import createI18n from "@/plugins/i18n";

export default {
  name: "HomeView",

  data: () => ({
    locale: createI18n.global.locale,
    language: navigator.language.substring(0, 2),
  }),
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #027be3; */
}

nav {
  padding: 30px;
}

nav a {
  /* font-weight: bold; */
  color: #027be3;
}

nav a.router-link-exact-active {
  color: #027be3;
}
</style>

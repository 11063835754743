<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
  </div>

  <div class="home">
    <h1>{{ $t("MSG001", language) }}</h1>
    <h4>{{ $t("MSG002", language) }}</h4>
    <h4>
      {{ $t("MSG003", language) }}
    </h4>
    <a href="https://web.uaitalk.com" target="_blank"
      >https://web.uaitalk.com</a
    >
    <h4>
      {{ $t("MSG006", language) }}
    </h4>
    <h4>{{ $t("MSG004", language) }}</h4>

    <div style="text-align: center; font-size: smaller">
      <p>{{ $t("MSG005", language) }} : {{ ident_lang }}</p>
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
import createI18n from "@/plugins/i18n";

export default {
  name: "HomeView",

  data: () => ({
    locale: createI18n.global.locale,
    language: navigator.language.substring(0, 2),
    ident_lang: navigator.language,
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #027be3;
}
</style>
